<template>
  <div ref="topDiv">
    <confirm-delete
      ref="modal"
      :msg="deleteMsg"
      :title="titleMsg"
      @on-submit="deleteOffer"
    />

    <!-- Duplicate offer confirm modal -->
    <confirm-delete
      ref="duplicatemodal"
      :msg="duplicateMsg"
      :title="duplicatetitleMsg"
      @on-submit="duplicateOffer"
    />
    <confirm-disable
      ref="disableModal"
      :msg="disableMsg"
      @on-submit="switchOfferStatusFunc"
      @on-hide="resetStatus"
    />
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>Status</label>
            <v-select
              v-model="status_filter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="offerStatusFilter"
              class="w-100"
              placeholder="Select Status"
              :reduce="val => val.value"
              :clearable="true"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>Trip Type</label>
            <v-select
              v-model="trip_filter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tripTypeFilter2"
              class="w-100"
              placeholder="Select Trip Type"
              :reduce="val => val.value"
              :clearable="true"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search by offer name or id or slug"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        v-if="!contentLoading"
        ref="refUserListTable"
        class="position-relative offer_table_cstm"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column Status -->
        <template #cell(id)="data">
          {{ data.item.id }}
        </template>

        <template #cell(slug)="data">
          <span v-if="data.item.slug">
            <a :href="data.item.slugUrl" target="_blank"><strong>{{ data.item.slug }}</strong></a>
          </span>
          <span v-else>-</span>
        </template>

        <template #cell(offer_name)="data">
          <div style="width: 150px">
            {{ data.item.offerLanguage[0].name }}
          </div>
        </template>

        <template #cell(start_date)="data">
          <div style="width: 110px">
            {{ data.item.startDate | formatDate }}
          </div>
        </template>

        <template #cell(end_date)="data">
          <div style="width: 110px">
            {{ data.item.endDate | formatDate }}
          </div>
        </template>

        <template #cell(tripType)="data">
          <div style="width: 80px">
            <b-badge
              v-if="data.item.savedTrips.type === 1"
              class="ml-1"
              variant="light-primary"
            >
              H
            </b-badge>

            <b-badge
              v-if="data.item.savedTrips.type === 3"
              class="ml-1"
              variant="light-primary"
            >
              H + F
            </b-badge>

            <b-badge
              v-if="data.item.savedTrips.type === 2"
              class="ml-1"
              variant="light-primary"
            >
              F
            </b-badge>
          </div>
        </template>

        <template #cell(checkin-checkout_date)="data">
          <div style="width: 200px">
            <b-badge pill class="text-capitalize">

              <span v-if="data.item.savedTrips.payload">
                {{
                  data.item.savedTrips.payload.checkin_date | formatDate
                }}
              </span>
              <span v-else> - </span>
            </b-badge>
            -
            <b-badge pill class="text-capitalize">
              <span v-if="data.item.savedTrips.payload">
                {{
                  data.item.savedTrips.payload.checkout_date
                    | formatDate
                }}
              </span>
              <span v-else> - </span>
            </b-badge>
          </div>
        </template>

        <!-- <template #cell(created_date)="data">
          <div style="width: 110px">
            {{ data.item.createdDate | formatDate }}
          </div>
        </template> -->

        <template #cell(status)="data">
          {{ offerStatus(data.item.status) }}
        </template>

        <template #cell(priority)="data">
          {{ data.item.priorityNumber }}
        </template>
        <template #cell(book_on_request)="data">
          <div>
            <b-form-checkbox
              v-model="data.item.isOnRequest"
              class="custom-control-primary"
              name="check-button"
              switch
              @change="
                openDisableModal(
                  data.item.id,
                  data.item.isOnRequest,
                  data.item.offerLanguage[0].name
                )
              "
            />
          </div>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            v-if="checkAccess.Edit || checkAccess.delete"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.Edit
              "
              :to="{ name: 'edit-offer', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.Add
              "
              @click="openDuplicateCnfmModal(data.item.id, data.item.offerLanguage[0].name)"
            >
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">Duplicate</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                ((checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.delete) &&
                  data.item.status === 2) ||
                  data.item.status === 3
              "
              @click="openModal(data.item.id, data.item.offerLanguage[0].name)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-clipboard:copy="data.item.url"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <feather-icon icon="ClipboardIcon" />
              <span class="align-middle ml-50">Copy Url</span>
            </b-dropdown-item>

            <b-dropdown-item @click="redirectToFrontEnd(data.item.url)">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <b-dropdown-item @click="publishUnpublishOffer(data.item.id)">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">{{
                data.item.status === 1 ? "Unpublish" : "Publish"
              }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <Loader v-if="contentLoading" />
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <!-- <span class="text-muted">Showing  of {{ totalUsers }} entries</span> -->
            <span class="text-muted">Showing {{ totalOffers >= 1 ? startIndex + 1 : startIndex }} to
              {{ endIndex > totalOffers ? totalOffers : endIndex }} of
              {{ totalOffers }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalOffers"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="pageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BBadge,
  BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  perPageOptions,
  perPage,
  currentPage,
  RoleEnum,
  resConditionCheck,
  TripType,
  offerStatus,
  offerStatusFilter,
  tripTypeFilter,
  deleteConfirmMsg
} from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import checkLoginRole from "@/helpers/checkLoginRole";
import offerService from "@/services/offer/offer.service";
import accessRightCheck from "@/helpers/accessRightCheck";
// import CurrencyService from "../../../services/currency/currency.service";
import ConfirmDelete from "@/views/confirm-delete/ConfirmDelete.vue";
import ConfirmDisable from "@/views/confirm-disable/ConfirmDisable.vue";
import Loader from "@/layouts/skeloton-loader/Loader.vue";
// import ConfirmDisable from "../../confirm-disable/ConfirmDisable.vue";
import axios from "axios";
import whitelabelService from '@/services/whitelabel/whitelabel.service'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BCardHeader,
    BCardBody,
    ConfirmDelete,
    ConfirmDisable,
    Loader,
    BBadge,
    BFormCheckbox
  },

  data() {
    return {
      contentLoading: true,
      searchQuery: "",
      sortBy: "",
      TripType,
      offerStatus,
      perPageOptions,
      perPage,
      totalOffers: 0,
      isSortDirDesc: true,
      currentPage,
      items: [],
      deleteMsg: "",
      disableMsg: "",
      RoleEnum,
      status2: "",
      titleMsg: "",
      startIndex: 0,
      endIndex: 0,
      errorData: "",
      offerId: "",
      checkLoginRole,
      offerStatusFilter,
      tripTypeFilter,
      checkAccess: {},
      status_filter: null,
      trip_filter: null,
      tableColumns: [
        { key: "id", sortable: true },
        { key: "slug", sortable: true },
        { key: "offer_name" },
        { key: "start_date" },
        { key: "end_date" },
        { key: "tripType" },
        { key: "checkin-checkout_date" },
        // { key: "created_date" },
        { key: "status" },
        { key: "priority", sortable: true },
        { key: "book_on_request" },
        { key: "actions" },
      ],
      duplicatetitleMsg: "",
      duplicateMsg: "",
      duplicateOfferId: "",
      whitelabelDetail: {},
      tripTypeFilter2: tripTypeFilter
    };
  },

  watch: {
    currentPage: {
      handler() {
        this.getOfferListing();
      }
    },

    perPage: {
      handler() {
        this.currentPage = 1;
        this.getOfferListing();
      }
    },

    sortBy: {
      handler(newValue) {
        this.sortBy = newValue === "priority" ? "priorityNumber" : newValue;
        this.getOfferListing();
      }
    },

    searchQuery: {
      handler() {
        this.getOfferListing();
      }
    },

    isSortDirDesc: {
      handler() {
        this.getOfferListing();
      }
    },
    status_filter: {
      handler() {
        this.currentPage = 1;
        this.getOfferListing();
      }
    },

    trip_filter: {
      handler() {
        this.currentPage = 1;
        this.getOfferListing();
      }
    }
  },

  async mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    if (
      this.checkLoginRole() !== RoleEnum.SuperAdmin
      && !this.checkAccess.Edit && !this.checkAccess.delete
    ) {
      this.tableColumns = [
        { key: "id", sortable: true },
        { key: "slug", sortable: true },
        { key: "offer_name" },
        { key: "start_date" },
        { key: "end_date" },
        { key: "tripType" },
        { key: "checkin-checkout_date" },
        // { key: "created_date" },
        { key: "status" },
        { key: "priority", sortable: true },
        { key: "book_on_request" },
      ];
    }
    await this.getOfferListing();
    await this.getWhitelabelDetails();
  },

  methods: {
    pageChange() {
      window.scrollTo(0, 0);
    },
    openDisableModal(id, status, name) {
      this.offerId = id;
      this.status2 = !status;
      this.$refs.disableModal.show();
      this.disableMsg = !status
        ? `Are you sure you want to remove this ${name} offer for book on request ?`
        : `Are you sure you want to add this ${name} offer for book on request?`;
    },
    async getOfferListing() {
      this.contentLoading = true
      const payload = {
        no_of_result: this.perPage,
        page: this.currentPage,
        order: this.isSortDirDesc === true ? "DESC" : "ASC",
        order_by: this.sortBy,
        search: this.searchQuery.trim(),
        status_filter: this.status_filter,
        trip_filter: this.trip_filter
      };
      await offerService
        .getOfferListing(payload)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.contentLoading = false;
            this.items = res.data.data;
            this.totalOffers = res.data.count;
            this.startIndex = this.currentPage * this.perPage - this.perPage;
            this.endIndex = this.startIndex + this.perPage;
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          this.contentLoading = false;
          if (error.response.status === 404) {
            this.items = [];
            this.totalOffers = 0;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          }
        });
    },

    async publishUnpublishOffer(id) {
      try {
        const res = await offerService.publishUnpublishOffer(id);
        if (resConditionCheck(res.status) && res.data.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "CheckIcon",
              variant: "success"
            }
          });
          await this.getOfferListing();
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    openModal(offerId, name) {
      this.titleMsg = deleteConfirmMsg;
      this.deleteMsg = `Are you sure you want to delete ${name} offer ?`;
      this.offerId = offerId;
      this.$refs.modal.show();
    },

    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Url copied to clipboard",
          icon: "CheckIcon",
          variant: "success"
        }
      });
    },
    redirectToFrontEnd(url) {
      window.open(url, "_blank");
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error in copying url",
          icon: "X-CircleIcon",
          variant: "danger"
        }
      });
    },

    deleteOffer(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.modal.loading = true;
      if (this.offerId) {
        offerService
          .deleteOffer(this.offerId)
          .then(res => {
            if (resConditionCheck(res.status)) {
              this.$refs.modal.loading = false;
              this.$refs.modal.toggleModal = false;
              this.items = this.items.filter(item => item.id !== this.offerId);
              if (this.items.length === 0 && this.currentPage !== 1) {
                this.currentPage -= 1;
                this.getOfferListing();
              } else {
                this.getOfferListing();
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.modal.loading = false;
            this.$refs.modal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    },
    resetStatus() {
      const foundIdex = this.items.findIndex(
        item => item.id === this.offerId
      );
      if (this.status2 === this.items[foundIdex].isOnRequest) {
        this.$set(this.items[foundIdex], "isOnRequest", !this.status2);
      } else {
        this.$set(this.items[foundIdex], "isOnRequest", this.status2);
      }
    },
    openDuplicateCnfmModal(offerId, name) {
      this.duplicatetitleMsg = "Duplicate Confirmation";
      this.duplicateMsg = `Are you sure you want to duplicate ${name} offer ?`;
      this.duplicateOfferId = offerId;
      this.$refs.duplicatemodal.show();
    },

    duplicateOffer(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.duplicatemodal.loading = true;
      if (this.duplicateOfferId) {
        offerService.duplicateOffer(this.duplicateOfferId).then(res => {
          if (resConditionCheck(res.status)) {
            this.$refs.duplicatemodal.loading = false;
            this.$refs.duplicatemodal.toggleModal = false;
            this.currentPage = 1;
            this.getOfferListing();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success"
              }
            });
          }
        }).catch(error => {
          this.$refs.duplicatemodal.loading = false;
          this.$refs.duplicatemodal.toggleModal = false;
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger"
            }
          });
        });
      }
    },

    async getWhitelabelDetails() {
      let wlCode = axios.defaults.headers.wl_code
      await whitelabelService.getWhitelabelDetail(wlCode)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.whitelabelDetail = res.data.data
            this.whitelabelDetail.settingsData.modules.map(e => {
              if (e.isActive === false) {
                const find = this.tripTypeFilter2.findIndex(i => e.name === i.label)
                find > -1 ? this.tripTypeFilter2.splice(find, 1) : ''
              }
            })
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger"
            }
          });
        })
    },

    switchOfferStatusFunc(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.disableModal.loading = true;
      if (this.offerId) {
        offerService.switchOfferStatus(this.offerId)
          .then(res => {
            this.$refs.disableModal.loading = false;
            this.$refs.disableModal.toggleModal = false;
            if (resConditionCheck(res.status)) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.disableModal.loading = false;
            this.$refs.disableModal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            const foundIdex = this.items.findIndex(
              item => item.id === this.languageId
            );
            this.$set(this.items[foundIdex], "isOnRequest", this.status2);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
